import React, { memo, useRef, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { animated } from '@react-spring/web';
import { detect } from 'detect-browser';
import { useImages, useRockVideo } from '../utils/hooks';
import classnames from 'classnames';

import * as css from './RockVideo.module.css';

const assetsQuery = graphql`
  query {
    images: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "rocks" }
        extension: { eq: "png" }
      }
    ) {
      nodes {
        name
        extension
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    videos: allRockVideo {
      nodes {
        path
        rock
        width
      }
    }
  }
`;

const supportedBrowsers = ['edge', 'firefox', 'chrome', 'opera'];
const notSupportedOs = ['iOS', 'Android OS', 'BlackBerry OS', 'Windows Mobile'];

/**
  This component renders a rock image followed by a rock video based on the rock prop
  We manually import the videos because dynamic imports are hard and you cannot run
  videos through Gatsby graphql.
**/
const RockVideo = ({
  rock,
  width,
  left,
  top,
  right,
  rotation = 0,
  moveX = 0,
  moveY = 0,
  moveRotation = 0,
  progress,
  className,
  position = 'absolute'
}) => {
  const [state, setState] = useState({ image: 'loading', video: 'hidden' });
  const ref = useRef();
  const data = useStaticQuery(assetsQuery);
  const images = useImages(data.images.nodes);
  const video = useRockVideo(data.videos.nodes, rock, width);
  const image = images[rock];

  // Called when the image loads
  const onImageLoad = () => {
    // If video can run in this browser, set it to load the video
    const browser = detect();
    if (
      browser &&
      supportedBrowsers.includes(browser.name) &&
      !notSupportedOs.includes(browser.os)
    ) {
      setState({
        image: 'loaded',
        video: 'loading'
      });
    } else {
      setState({
        image: 'loaded',
        video: 'hidden'
      });
    }
  };

  // Called when the video loads
  const onVideoLoad = e => {
    // This gets called more than once, so only call when loading
    if (state.video === 'loading') {
      setState({
        image: 'loaded',
        video: 'loaded'
      });
      e.target.play();
    }
  };

  // If image is loaded from cache, it won't trigger an onLoad
  // so we manually call it here if it's already loaded.
  useEffect(() => {
    if (ref.current.complete) {
      onImageLoad();
    }
  }, []);

  return (
    <animated.div
      className={classnames(css.root, className)}
      style={{
        width,
        position,
        left,
        top,
        right,
        transform: progress
          ? progress.to(
              p =>
                `translate(${moveX * p}px, ${moveY * p}px) rotate(${
                  rotation + moveRotation * p
                }deg)`
            )
          : null
      }}
    >
      <picture>
        {image.images.sources.map(source => (
          <source key={source.type} {...source} />
        ))}
        <img
          ref={ref}
          className={classnames(css.image, {
            [css.loaded]: state.image === 'loaded',
            [css.hidden]: state.video === 'loaded'
          })}
          alt=""
          onLoad={onImageLoad}
          {...image.images.fallback}
        />
      </picture>
      {state.video !== 'hidden' && (
        <video
          loop
          muted
          playsInline
          preload="auto"
          width={width}
          className={classnames(css.video, {
            [css.loaded]: state.video === 'loaded'
          })}
          onCanPlayThrough={onVideoLoad}
        >
          <source src={video} type="video/webm" />
        </video>
      )}
    </animated.div>
  );
};

export default memo(RockVideo);
