import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import superbrightPoster from '../images/superbright.jpg';

function Head({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            host
          }
        }
      }
    `
  );

  const defaultTitle = site.siteMetadata?.title;

  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title ? ` ${title} by ${defaultTitle} ` : defaultTitle;
  const metaPoster = site.siteMetadata.host + superbrightPoster;
  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `viewport`,
          content: `width=device-width,initial-scale=1`
        },
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: metaTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: metaPoster
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`
        },
        {
          name: `twitter:creator`,
          content: `superbright_me`
        },
        {
          name: `twitter:title`,
          content: metaTitle
        },
        {
          name: `twitter:image`,
          content: metaPoster
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
    >
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <link rel="alternate icon" href="/favicon.png" sizes="32x32" />
      <link rel="mask-icon" href="/favicon.svg" color="#000" />
    </Helmet>
  );
}

Head.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
};

Head.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string
};

export default Head;
