import React from 'react';
import classnames from 'classnames';

import * as css from './Logo.module.css';

const Logo = ({ size, className }) => {
  return <span className={classnames(css.root, className)}>CUPERBRIGHT</span>;
};

export default Logo;
