import React, { memo, useRef, useEffect, useState } from 'react';

import { useResizing } from '../utils/hooks';
import { breakpoints, getWindowSize } from '../utils';

import * as css from './HeightGuarantee.module.css';

// This component guarantees that the content is of a specific
// multiple of a number. It does this by getting the height and
// inserting an empty box below to make up for the difference.
// The component is disabled on screens smaller than 900px
const HeightGuarantee = ({
  children,
  className = null,
  block = 104,
  line = 8,
  // This is the delay on calculation of the heightguarantee
  ms = 300,
  // This can be changed to recalulate the height guarantee
  recalculate,
  onHeightChange,
  breakpoint = breakpoints.contained
}) => {
  const ref = useRef();
  const isResizing = useResizing();
  const [extraHeight, setExtraHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      const [winWidth] = getWindowSize();
      const bounds = ref.current.getBoundingClientRect();
      if (winWidth < breakpoint) {
        setExtraHeight(0);
        onHeightChange && onHeightChange(bounds.height);
      } else {
        const remainder = bounds.height % (block + line);
        // Catch if the remainder falls between 104 and 112,
        // we get a negative number which is not supported in height.
        setExtraHeight(Math.max(0, block - remainder));
        onHeightChange && onHeightChange(bounds.height + block - remainder);
      }
    };
    if (ref.current && !isResizing) {
      const timeout = setTimeout(updateHeight, ms);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [
    ref,
    block,
    line,
    isResizing,
    ms,
    recalculate,
    breakpoint,
    onHeightChange
  ]);

  return (
    <div>
      <div ref={ref} className={className}>
        <div className={css.invisible}>&nbsp;</div>
        {children}
        <div className={css.invisible}>&nbsp;</div>
      </div>
      <div className={css.extra} style={{ height: extraHeight }} />
    </div>
  );
};

export default memo(HeightGuarantee);
