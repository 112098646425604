import { supportsPassiveEvents } from 'detect-it';

// These must be kept up to date with the CSS custom media
export const breakpoints = {
  small: 600,
  reduced: 750,
  medium: 900,
  large: 1150,
  xlarge: 1400,
  contained: 1700,
  largest: 9999
};

export const breakpointNames = Object.keys(breakpoints);

export const getBreakpoint = width => {
  if (width === null) {
    return null;
  }
  for (let i = 0; i < breakpointNames.length; i++) {
    if (width <= breakpoints[breakpointNames[i]]) {
      return breakpointNames[i];
    }
  }
};

// A function that returns true/false as to whether the current
// breakpoint is covered by the needed breakpoint.
export const isBreakpoint = (breakpoint, needed) => {
  return (
    breakpoint &&
    breakpointNames.indexOf(breakpoint) <= breakpointNames.indexOf(needed)
  );
};

// This function receives the name of the current breakpoint
// as well as an object like this:
// { largest: 1, medium: 2 }
// It returns the first value from the breakpoint that is current
// or below. This is why it is required to have a "largest" key for fallback.
export const getBreakpointValue = (breakpoint, values) => {
  if (!values.hasOwnProperty('largest')) {
    console.error('pick called without largest', values);
  }

  if (!breakpoint) {
    return null;
  }

  // If there is a value with this exact breakpoint
  if (values.hasOwnProperty(breakpoint)) {
    return values[breakpoint];
  }

  // Otherwise look
  const idx = breakpointNames.indexOf(breakpoint);
  for (let i = idx; i < breakpointNames.length; i++) {
    if (values.hasOwnProperty(breakpointNames[i])) {
      return values[breakpointNames[i]];
    }
  }

  throw new Error(
    `Breakpoint not found. Current: ${breakpoint}, Values: ${JSON.stringify(
      values
    )}`
  );
};

// A function to return a number between two numbers based on the
export const numberBetween = (min, max) => {
  return min + Math.round(Math.random() * (max - min));
};

export const getWindowSize = () => [
  window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth,
  window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
];

export const uid = (prefix = 'comp') => {
  return prefix + '-' + Math.random().toString(36).substring(2, 16);
};

export const map = (n, start1, stop1, start2, stop2, ease) => {
  let prog = Math.min(1, Math.max(0, (n - start1) / (stop1 - start1)));
  if (ease) {
    prog = ease(prog);
  }
  return prog * (stop2 - start2) + start2;
};

export const isUrl = url => {
  if (!url) return false;
  return typeof url === 'string' && /^https?\/\//.test(url);
};

export const prettyUrl = url => {
  if (!url) return url;
  return url.replace('http://', '').replace('https://', '');
};

export const formatTime = time => {
  let minutes = Math.floor(Math.floor(time) / 60);
  let seconds = time - minutes * 60;
  if (minutes < 0) return '00:00';
  if (minutes < 10) minutes = `0${minutes}`;
  if (seconds < 10) seconds = `0${seconds}`;
  return `${minutes}:${seconds}`;
};

export const passiveArg = supportsPassiveEvents ? { passive: true } : false;

export const round = (num, roundTo) => {
  if (num > 0) return Math.ceil(num / roundTo) * roundTo;
  else if (num < 0) return Math.floor(num / roundTo) * roundTo;
  else return roundTo;
};
