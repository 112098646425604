import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Footer from '../components/Footer';

import * as css from './Main.module.css';
import 'sanitize.css';
import '../styles/variables.css';
import '../styles/base.css';
import '../styles/fonts.css';

const MainLayout = ({ children, className }) => {
  return (
    <div className={classnames(css.root, className)}>
      <main>{children}</main>
      <Footer />
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default MainLayout;
