import React, { memo } from 'react';
import { Link } from 'gatsby';
import { FaFacebookF, FaInstagram, FaGithub, FaTwitter } from 'react-icons/fa';
import classnames from 'classnames';

import { SidesContainer, SimpleContainer } from './Container';
import Logo from './Logo';
import RockVideo from './RockVideo';

import {
  useWindowSize,
  useBreakpoint,
  useBreakpointValue
} from '../utils/hooks';

import * as css from './Footer.module.css';
import * as grid from '../styles/grid.module.css';

const breakpointValues = {
  small: {
    width: 500,
    top: -50,
    left: 20
  },
  reduced: {
    width: 570,
    top: -50,
    left: 20
  },
  medium: {
    width: 670,
    top: -50,
    left: 20
  },
  large: {
    width: 800,
    top: -100,
    left: 50
  },
  largest: {
    width: 1100,
    top: -100,
    left: 50
  }
};

const Footer = () => {
  const [winWidth] = useWindowSize();
  const breakpoint = useBreakpoint(winWidth);
  const breakpointValue = useBreakpointValue(breakpoint, breakpointValues);

  return (
    <>
      <SidesContainer tag="footer" bottomLine={true} tagClassName={grid.invert}>
        <div className={classnames(css.top, grid.col)}>
          {breakpointValue && <RockVideo rock="rock03" {...breakpointValue} />}
          <p className={css.address}>
            <Link to="/" className={css.logo}>
              <Logo />
            </Link>
            New York
            <br />
            London
            <br />
            <br />
            <a href="mailto:hello@superbright.me">hello@superbright.me</a>
          </p>
          <ul className={css.menu}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/#work">Work</Link>
            </li>
            <li>
              <Link to="/#about">About</Link>
            </li>
          </ul>
        </div>
      </SidesContainer>
      <SimpleContainer className={css.bottom} tagClassName={grid.invert}>
        <div className={classnames(grid.col, css.copyright)}>
          <p> &#169; QUANTIZE LLC. All rights reserved.</p>
        </div>
        <ul className={classnames(grid.col, css.socialMedia)}>
          <li>
            <a
              href="https://www.instagram.com/superbright_/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/superbright_me"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/Superbrightme-685676934818450/"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookF />
            </a>
          </li>
          <li>
            <a href="https://github.com/superbright">
              <FaGithub />
            </a>
          </li>
        </ul>
      </SimpleContainer>
    </>
  );
};

export default memo(Footer);
