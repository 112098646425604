import React from 'react';
import classnames from 'classnames';

import Line from './Line';
import HeightGuarantee from './HeightGuarantee';

import * as css from './Container.module.css';
import * as grid from '../styles/grid.module.css';

// A component that centers the content and wraps it in a grid class.
// Often used for content that is exactly the height of the baseline pattern.
export const SimpleContainer = ({
  children,
  topLine,
  bottomLine,
  tag = 'div',
  className,
  tagClassName,
  id
}) => {
  const Tag = `${tag}`;
  return (
    <Tag className={classnames(css.simple, tagClassName)} id={id}>
      {topLine && <Line patternX="50%" />}
      <div className={classnames(grid.grid, css.simpleContent, className)}>
        {children}
      </div>
      {bottomLine && <Line patternX="50%" />}
    </Tag>
  );
};

// A component that can show content of any height with lines in the sides.
// It guarantees always to be a height that fits with the baseline pattern.
export const SidesContainer = ({
  children,
  topLine,
  bottomLine,
  tag = 'div',
  className,
  tagClassName,
  id,
  ms,
  recalculate,
  onHeightChange
}) => {
  const Tag = `${tag}`;
  return (
    <Tag className={classnames(css.sides, tagClassName)} id={id}>
      {topLine && <Line patternX="50%" />}
      <HeightGuarantee
        ms={ms}
        recalculate={recalculate}
        onHeightChange={onHeightChange}
      >
        <div className={css.cols}>
          <div className={css.left}>
            <Line isFlexbox={true} patternX="100%" spacing={true} />
          </div>
          <div className={classnames(grid.grid, css.sidesContent, className)}>
            {children}
          </div>
          <div className={css.right}>
            <Line isFlexbox={true} spacing={true} />
          </div>
        </div>
      </HeightGuarantee>
      {bottomLine && <Line patternX="50%" />}
    </Tag>
  );
};
