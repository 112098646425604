import React, { memo } from 'react';
import classnames from 'classnames';
import { useLineRender } from '../utils/hooks';
import lineInfo from '../fonts/line.json';

import * as css from './Line.module.css';

const Line = ({ patternX = 0, isFlexbox, spacing, className }) => {
  const [key, id] = useLineRender();
  return (
    <svg
      key={key}
      className={classnames(css.root, className, {
        [css.flexbox]: isFlexbox,
        [css.line]: !isFlexbox
      })}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <defs>
        <pattern
          id={id}
          x={patternX}
          y={spacing ? 104 : 0}
          width={lineInfo.patternWidth}
          height={112}
          patternUnits="userSpaceOnUse"
        >
          <text
            className={css.txt}
            x={0}
            y={lineInfo.fontSize}
            fontSize={lineInfo.fontSize}
            letterSpacing={lineInfo.letterSpacing}
          >
            {patternX === '100%'
              ? 'UPERBRIGHTHFILALDWMC'
              : 'CUPERBRIGHTHFILALDWM'}
          </text>
        </pattern>
      </defs>
      <rect fill={`url(#${id})`} width="100%" height="100%" />
    </svg>
  );
};

export default memo(Line);
