import { useMemo, useState, useEffect, useRef } from 'react';
import {
  uid,
  getWindowSize,
  getBreakpoint,
  getBreakpointValue,
  passiveArg
} from './index';

/**
  Hook to figure out where fonts are loaded.
**/
export const useFontsReady = (ms = 0) => {
  const [fontsReady, setFontsReady] = useState(false);
  useEffect(() => {
    let mounted = true;
    let timeout;
    document.fonts.ready.then(() => {
      if (mounted) {
        if (ms === 0) {
          setFontsReady(true);
        } else {
          timeout = setTimeout(() => setFontsReady(true), ms);
        }
      }
    });
    return () => {
      mounted = false;
      clearTimeout(timeout);
    };
  }, [ms]);
  return fontsReady;
};

/**
  Triggers multiple renders for every line object to bypass Chrome's bug where displaying
  an SVG pattern before the webfont is loaded will render a blank line.

  Triggers the following loading states:
  1. initial - initial server and client render
  2. second - second render in the client
  3. fonts - when the fonts are loaded
  4. delay - a delay after the fonts are loaded
**/
export const useLineRender = () => {
  const [state, setState] = useState(['initial', null]);

  useEffect(() => {
    const id = uid('line');
    setState(['second', id]);

    let mounted = true;
    let timeout;

    document.fonts.ready.then(() => {
      if (mounted) {
        setState(['fonts', id]);
        // I am unsure whether this is needed, but just to be safe
        timeout = setTimeout(() => setState(['delay', id]), 500);
      }
    });

    return () => {
      mounted = false;
      clearTimeout(timeout);
    };
  }, []);

  return state;
};

export const useUid = prefix => {
  return useMemo(() => uid(prefix), [prefix]);
};

/**
  Hook that tells you whether this is the first render
**/
export const useFirstRender = () => {
  const firstRender = useRef(true);
  useEffect(() => {
    firstRender.current = false;
  });
  return firstRender.current;
};

/**
 * Flips a boolean when the code is rendered in the browser.
 */
export const useClientRender = nodes => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);
  return isClient;
};

/*
 * Returns true when the run for the first time (server or client)
 * and false otherwise for the duration of the app.
 */

let firstRun = true;

export const useFirstRenderEver = () => {
  useEffect(() => {
    firstRun = false;
  }, []);
  return firstRun;
};

/**
  A hook that returns the window size, also during resize.
  `ssrWidth` and `ssrHeight` controls what the window size should be when the
  static site renders (where there is no window) and on the first render
  (because the values have to be the same on hydration). Components can use
  these intial values to only render when
**/
export const useWindowSize = () => {
  const firstRenderEver = useFirstRenderEver();
  const [win, setWin] = useState(
    firstRenderEver ? [null, null] : getWindowSize()
  );
  useEffect(() => {
    setWin(getWindowSize());
    const handleResize = () => {
      window.requestAnimationFrame(() => {
        setWin(getWindowSize());
      });
    };
    window.addEventListener('resize', handleResize, passiveArg);
    return () => {
      window.removeEventListener('resize', handleResize, passiveArg);
    };
  }, []);

  return win;
};

export const useBreakpoint = winWidth => {
  return useMemo(() => getBreakpoint(winWidth), [winWidth]);
};

export const useBreakpointValue = (breakpoint, values) => {
  return useMemo(
    () => getBreakpointValue(breakpoint, values),
    [breakpoint, values]
  );
};

export const useResizing = (resetTime = 300) => {
  const [isResizing, setIsResizing] = useState(false);
  useEffect(() => {
    let timer;
    let oldWidth = window.innerWidth;
    const handleResize = () => {
      clearTimeout(timer);
      setIsResizing(oldWidth !== window.innerWidth);
      oldWidth = window.innerWidth;
      timer = setTimeout(() => {
        setIsResizing(false);
      }, resetTime);
    };
    window.addEventListener('resize', handleResize, passiveArg);
    return () => {
      window.removeEventListener('resize', handleResize, passiveArg);
      clearTimeout(timer);
    };
  }, [resetTime]);

  return isResizing;
};

// A hook that lets us use IntersectionObserver
// Source: https://medium.com/the-non-traditional-developer/how-to-use-an-intersectionobserver-in-a-react-hook-9fb061ac6cb5
export const useIntersect = ({ root = null, rootMargin, threshold = 0 }) => {
  const [appearedOnScreen, setAppearedOnScreen] = useState(false);
  const [node, setNode] = useState(null);
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setAppearedOnScreen(true);
        }
      },
      {
        root,
        rootMargin,
        threshold
      }
    );
  }, [root, rootMargin, threshold]);

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();
    if (node && !appearedOnScreen) {
      currentObserver.observe(node);
    }
    return () => currentObserver.disconnect();
  }, [node, appearedOnScreen]);

  return [setNode, appearedOnScreen];
};

/**
  Hook used to combine work items and images named anything with `poster`
**/
export const usePreparedWorkItems = (items, images = []) => {
  return useMemo(() => {
    const prepared = [];
    for (let i = 0; i < items.length; i++) {
      const item = Object.assign(
        { posters: [] },
        items[i].fields,
        items[i].childJavascriptFrontmatter.frontmatter
      );

      for (let j = 0; j < images.length; j++) {
        // eslint-disable-next-line
        const [section, project] = images[j].relativeDirectory.split('/');
        if (items[i].name === project) {
          item.posters.push(
            images[j].childImageSharp?.gatsbyImageData ?? images[j]
          );
        }
      }

      prepared.push(item);
    }
    return prepared;
  }, [items, images]);
};

/**
 * Takes an array of images nodes and makes a hashed object based on their names
 */
export const useImages = nodes => {
  return useMemo(() => {
    const images = {};
    for (let i = 0; i < nodes.length; i++) {
      if (/(jpe?g|gif|png)/.test(nodes[i].extension)) {
        images[nodes[i].name] =
          nodes[i]?.childImageSharp?.gatsbyImageData ?? nodes[i];
      }
    }
    return images;
  }, [nodes]);
};

/**
 * Used in the RockVideo component to grab the desired rock video
 */
export const useRockVideo = (nodes, rock, width) => {
  // Make array of sorted widths based on rock
  const sizes = useMemo(() => {
    const sizes = [];
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].rock === rock) {
        sizes.push(nodes[i]);
      }
    }
    sizes.sort((a, b) => a.width - b.width);
    return sizes;
  }, [nodes, rock]);

  // Find the first video that is 1.5 size of the width
  // This is a somewhat okay solution for both retina and normal screens
  for (let i = 0; i < sizes.length; i++) {
    if (sizes[i].width >= width * 1.5) {
      return sizes[i].path;
    }
  }

  return sizes[sizes.length - 1].path;
};

/**
  A hook that listens to scrollling events and returns wether the menu should be visible
**/

export const useMenuScroll = ({ menuHeight = 100, threshold = 200 } = {}) => {
  const [showMenu, setShowMenu] = useState(null);
  const [scrollTop, setScrollTop] = useState(null);
  const delta = useRef();
  const pos = useRef();

  useEffect(() => {
    const handleScroll = () => {
      window.requestAnimationFrame(() => {
        const newPos = window.pageYOffset;
        setScrollTop(newPos);
        // if scroll postiion is less than 50, set to null.
        if (newPos < menuHeight) {
          setShowMenu(null);
          pos.current = newPos;
          return;
        }

        if (delta.current <= 0 && newPos - pos.current < 0) {
          delta.current += newPos - pos.current;
        } else {
          delta.current = 0;
        }
        setShowMenu(delta.current < -1 * threshold);

        pos.current = newPos;
      });
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll, false);
    return () => {
      window.removeEventListener('scroll', handleScroll, false);
    };
  }, [menuHeight, threshold]);

  return [showMenu, scrollTop];
};
